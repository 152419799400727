'use client';
import React from 'react';
import Image from 'next/image';
import { IKButton } from '@/components/shared/material';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { MenuItemLink } from '@/types/types';

export type SlideType = {
  title: string;
  image: {
    url: string;
    alt?: string;
    placeholderUrl?: string;
  };
  button: {
    text: string;
    link: MenuItemLink;
  };
};

export default function ShopBySlide({ slide }: { slide: SlideType }) {
  return (
    <>
      <div className='relative isolate flex flex-col justify-center overflow-hidden rounded-2xl p-4'>
        <div className='inset absolute inset-0 -z-10 bg-gradient-to-t from-charcoal-700 to-charcoal-900'></div>
        <Image
          src={slide.image.url}
          alt={slide.image.alt ?? slide.title}
          width={500}
          height={500}
          className='h-full w-full object-cover'
        />

        <div className='absolute bottom-4 left-4 right-4 overflow-hidden'>
          {slide.button && (
            <IKButton
              link={slide.button.link}
              color={'white'}
              variant={'outlined'}
              size={'md'}
              className={`relative flex w-full min-w-full items-center justify-start gap-1 overflow-hidden border-white/30 px-1 py-2 md:px-8 md:py-4 lg:w-auto`}
            >
              {slide.button.text}
              <div className='bg-gradient-transparent absolute inset-0 -z-10' />
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className='ml-2'
              >
                <path
                  d='M9.28574 15.1326L15.629 8.79266C15.7324 8.69868 15.8149 8.58442 15.8714 8.45713C15.9279 8.32985 15.957 8.19232 15.957 8.05327C15.957 7.91422 15.9279 7.7767 15.8714 7.64941C15.8149 7.52213 15.7324 7.40787 15.629 7.31388L9.28574 0.973961C9.09705 0.790828 8.84911 0.679723 8.58576 0.66029C8.32241 0.640857 8.0606 0.714347 7.84663 0.867767C7.71805 0.953173 7.61094 1.06667 7.53355 1.19954C7.45615 1.3324 7.41052 1.48109 7.40017 1.63416C7.38983 1.78724 7.41503 1.94064 7.47386 2.08255C7.53269 2.22446 7.62356 2.3511 7.7395 2.45275L12.3239 6.9953H1.02525C0.749742 7.00588 0.489073 7.12181 0.297924 7.31876C0.106774 7.51571 0 7.77839 0 8.05169C0 8.32498 0.106774 8.58766 0.297924 8.78461C0.489073 8.98157 0.749742 9.0975 1.02525 9.10808H12.3255L7.7411 13.6522C7.62516 13.7539 7.53428 13.8805 7.47546 14.0224C7.41663 14.1643 7.39143 14.3177 7.40177 14.4708C7.41212 14.6239 7.45775 14.7726 7.53514 14.9054C7.61254 15.0383 7.71965 15.1518 7.84823 15.2372C8.0622 15.3906 8.32401 15.4641 8.58736 15.4447C8.85071 15.4252 9.09865 15.3141 9.28734 15.131L9.28574 15.1326Z'
                  fill='#fff'
                />
              </svg>
            </IKButton>
          )}
        </div>
      </div>
    </>
  );
}
