'use client';
import MuxPlayer from '@mux/mux-player-react';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { m, useInView, AnimatePresence } from 'framer-motion';
import { PortableTextBlock } from 'sanity';
import { fadeUp } from '@/assets/framer/animations';
import { components, noParagraphs } from '../PortableTextComponents';
import { PortableText } from '@portabletext/react';
import { AcceptedPaymentMethods } from '@/components/shared/groupIcons';

export default function ProductVideoAninmation(props: {
  data: {
    sectionId?: string;
    heading?: PortableTextBlock;
    muxVideoId: string;
    videoToasts: { time: number; value: { text: string } }[];
    acceptedPaymentMethods: {
      showAcceptedPaymentMethods: boolean;
      acceptedPaymentMethodsText: PortableTextBlock;
    };
  };
}) {
  const section = useRef(null);
  const video = useRef<null | HTMLVideoElement>(null);
  const [activeCuePoint, setActiveCuePoint] = useState<any>(null);
  const [sellingPoints, setSellingPoints] = useState<any[]>([]);

  const isVideoInView = useInView(video, { amount: 0.2, once: false });
  const isInView = useInView(section, { amount: 0.4, once: true });
  const sectionId = props.data?.sectionId;
  const heading = props.data?.heading;
  const muxVideoId = props.data?.muxVideoId;
  const videoToasts = props.data?.videoToasts;
  const acceptedPaymentMethods = props.data?.acceptedPaymentMethods;

  useEffect(() => {
    if (video.current) {
      let muxPlayerEl = video.current;

      const handleCuePointChange = () => {
        // @ts-expect-error
        const activeCuePointObj = muxPlayerEl.activeCuePoint;
        setActiveCuePoint(activeCuePointObj);
        if (activeCuePointObj?.time !== 0) {
          setSellingPoints((prev) => {
            if (
              activeCuePointObj.value.text &&
              !prev.some((point) => point.time === activeCuePointObj.time)
            ) {
              return [
                ...prev,
                {
                  text: activeCuePointObj.value.text,
                  active: true,
                  time: activeCuePointObj.time,
                },
              ];
            } else {
              return prev;
            }
          });
        }
      };

      muxPlayerEl.addEventListener('cuepointchange', handleCuePointChange);

      // Cleanup
      return () => {
        muxPlayerEl.removeEventListener('cuepointchange', handleCuePointChange);
      };
    }
  }, [video]);

  const handleDurationChange = (e: any) => {
    let muxPlayerEl = e.target;

    if (muxPlayerEl.readyState && videoToasts && videoToasts.length) {
      muxPlayerEl.addCuePoints(videoToasts);
    }
  };

  const setVideoToCuePointHandler = useCallback(
    (point: { time: number }) => {
      let videoEl = video.current;
      if (videoEl && videoEl.readyState >= 2 && point.time) {
        videoEl.currentTime = point.time;
        videoEl.play();
      }
    },
    [video]
  );

  useEffect(() => {
    // let videoDimensions = video.current.getBoundingClientRect();
    if (isVideoInView && video.current) {
      video.current.play();
    } else if (!isVideoInView && video.current) {
      video.current.pause();
    }

    if (
      (isVideoInView &&
        video.current?.currentTime === video.current?.duration) ||
      video.current?.currentTime === 0
    ) {
      setSellingPoints([]);
    }

    if (
      !isVideoInView &&
      video.current?.currentTime === video.current?.duration
    ) {
      setSellingPoints([]);
    }
  }, [isVideoInView, video]);

  return (
    <>
      <section
        id={sectionId}
        ref={section}
        className='relative z-40 -mb-28 scroll-m-16 bg-[#121112] bg-gradient-to-b pt-5 sm:pt-0 md:mb-14 lg:mb-20'
      >
        {/* Top Divider wave */}
        <div className='absolute left-0 top-0 h-max w-full -translate-y-[calc(100%_-_1px)] transform overflow-hidden'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='w-[200%] fill-[#121112] md:w-full'
            width='1464'
            height='34'
            viewBox='0 0 1464 34'
            fill='none'
            preserveAspectRatio='none'
          >
            <path
              className='fill-[#121112]'
              d='M0 34H1464V13.9567C1462.14 14.0919 1459.76 14.2755 1456.86 14.4993L1456.79 14.5044C1400.39 18.8525 1148.67 38.2577 731.531 12.6924C315.789 -12.7874 65.0374 7.19974 7.26685 11.8046C4.35205 12.0369 1.92871 12.23 0 12.3763V34Z'
              fill='none'
            />
          </svg>
        </div>

        <div className='container-padding container absolute left-0 right-0 top-14 z-10 mx-auto flex h-full w-full flex-col pt-0 sm:pt-10 md:block md:h-[unset] md:pt-0'>
          {heading && (
            <m.h2
              initial='hidden'
              animate={isInView ? 'visible' : 'hidden'}
              variants={fadeUp}
              className='heading-xl relative z-10 max-w-[450px] font-display font-medium leading-[1] tracking-tighter text-yellow-150 sm:max-w-[550px] md:leading-[0.95] [&_strong]:text-yellow'
            >
              {heading && (
                <PortableText
                  components={components}
                  value={heading}
                ></PortableText>
              )}
            </m.h2>
          )}
          <div
            className={`${acceptedPaymentMethods ? 'min-h-[370px]' : ''} absolute bottom-[200px] w-[calc(100%_-_40px)] max-w-[unset] overflow-hidden md:relative md:bottom-[unset] md:max-w-[380px] md:overflow-visible`}
          >
            <div className='z-20 mt-14 flex min-h-[170px] flex-col gap-3 md:justify-center'>
              <AnimatePresence mode='wait' initial={false}>
                {sellingPoints.map((point, index) => (
                  <Fragment key={`cue-point-'${index}`}>
                    <m.div
                      onClick={() => setVideoToCuePointHandler(point)}
                      initial={{ opacity: 0, x: 200 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{
                        duration: 1.5,
                        type: 'spring',
                      }}
                      className={`flex min-w-[300px] items-center gap-4 rounded-2xl px-5 py-3 text-base font-bold text-teal hover:cursor-pointer hover:sm:bg-teal-700/80 md:text-xl ${
                        activeCuePoint.time === point.time
                          ? 'bg-teal-700 md:bg-teal-700/80'
                          : 'bg-[#252525]/[95%] md:bg-[#282825]/40'
                      }`}
                    >
                      <span className='inline-block h-2 w-2 flex-shrink-0 rounded-full bg-teal'></span>
                      <span>{point.text}</span>
                    </m.div>
                  </Fragment>
                ))}
              </AnimatePresence>
            </div>
          </div>
        </div>

        <div className='relative mx-auto flex h-[min(100vh,_600px)] justify-center overflow-hidden pt-10 sm:h-[min(100vh,_700px)] md:h-[min(100vh,_800px)] md:pt-0'>
          <div className='pointer-events-none sticky top-0 h-[min(100vh,_500px)] select-none sm:h-[min(100vh,_700px)] md:h-[min(100vh,_800px)] md:w-[min(100vh,_800px)]'>
            <div className='absolute left-0 top-0 z-20 h-full w-full shadow-[2px_2px_60px_27px_#121112_inset]'></div>
            {/* Gradient Overlay */}
            {/* <div className='absolute z-10 h-full w-full bg-gradient-radial from-[#121112]/[0%] from-0% via-[#121112]/[40%] via-[40%] to-[#121112] to-80%'></div> */}
            {/* Video */}
            {muxVideoId && (
              <MuxPlayer
                // @ts-expect-error
                ref={video}
                className={`pointer-events-none`}
                onDurationChange={handleDurationChange}
                muted={true}
                nohotkeys
                nospace
                controls={false}
                autoPlay={true}
                accent-color='#121112'
                style={{
                  /* Target all sections by excluding the section prefix */
                  // @ts-expect-error
                  '--play-button': 'none',
                  '--live-button': 'none',
                  '--seek-backward-button': 'none',
                  '--seek-forward-button': 'none',
                  '--mute-button': 'none',
                  '--captions-button': 'none',
                  '--airplay-button': 'none',
                  '--pip-button': 'none',
                  '--fullscreen-button': 'none',
                  '--cast-button': 'none',
                  '--playback-rate-button': 'none',
                  '--volume-range': 'none',
                  '--time-range': 'none',
                  '--time-display': 'none',
                  '--duration-display': 'none',
                  '--rendition-selectmenu': 'none',
                  '--center-controls': 'none',
                  '--controls': 'none',
                  aspectRatio: '1/1',
                  '--controls-backdrop-color': 'rgb(0 0 0 / 0)',
                  accentColor: '#121112',
                  height: '100%',
                  width: '100%',
                  left: 0,
                  backgroundColor: 'transparent',
                  top: 0,
                }}
                streamType='on-demand'
                playbackId={muxVideoId}
                metadataVideoTitle='iK Flyer Card Machine'
                primaryColor='#FFFFFF'
                secondaryColor='#000000'
              />
            )}
          </div>
        </div>

        {/* Bottom Divider wave */}
        <div className='absolute bottom-0 left-0 h-max w-full translate-y-[calc(100%_-_1px)] transform overflow-hidden'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='w-[200%] md:w-full [&_path]:fill-[#121112]'
            width='1464'
            height='26'
            viewBox='0 0 1464 26'
            fill='none'
            preserveAspectRatio='none'
          >
            <path
              d='M0 0V13.4014C71.6641 8.27045 311.629 -4.51068 731.531 15.2506C1153.28 35.099 1393.51 22.1175 1464 17.0324V0H0Z'
              fill='none'
            />
          </svg>
        </div>

        <div className='container-padding mb w-full transform items-center justify-center pb-28 md:absolute md:bottom-0 md:mb-0 md:flex md:translate-y-[50%] md:pb-0'>
          {acceptedPaymentMethods?.showAcceptedPaymentMethods ? (
            <div className='bg-gradient-charcoal flex flex-col items-center justify-center gap-2 rounded-3xl bg-charcoal-900 px-5 py-7 md:min-w-[530px] md:px-10'>
              {acceptedPaymentMethods?.acceptedPaymentMethodsText && (
                <h4 className='text-gray-300'>
                  <PortableText
                    components={noParagraphs}
                    value={acceptedPaymentMethods.acceptedPaymentMethodsText}
                  />
                </h4>
              )}
              <AcceptedPaymentMethods
                color='white'
                className='w-full opacity-80'
              />
            </div>
          ) : (
            <>
              <div className='h-20'></div>
            </>
          )}
        </div>
      </section>
    </>
  );
}
