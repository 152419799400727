'use client';
import { Store } from '@/types/types';
import { atom, createStore } from 'jotai';

export const isFooterInViewAtom = atom<boolean>(false);
export const selectedStoreAtom = atom<Store | null>(null);
export const navbarBgColorAtom = atom('charcoal');
export const navbarOverlayAtom = atom('overlay');

export const globalStore = createStore();
