'use client';
import React, { useEffect, useMemo, useRef } from 'react';
import Image from 'next/image';
import { Card, IKButton, IKLink } from '@/components/shared/material';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import { components } from '@/components/sections';
import { m, useInView } from 'framer-motion';
import { MenuItemLink } from '@/types/types';
import { AcceptedPaymentMethods } from '@components/shared/groupIcons';
import { formatPriceWithoutCents } from '@/sanity/utils/shopify/priceHelpers';
import { mixpanelProductImpression } from '@/lib/mixpanel/services/client';
import { IKPattern } from '../patterns';
import { useStatsigClient } from '@statsig/react-bindings';

export type FeatureCardType = {
  image?: {
    url?: string;
    alt?: string;
    placeholderUrl?: string;
  };
  product?: {
    sanityTitle: string;
    title: string;
    slug: string;
    categorySlug: string;
    price: number;
    variantGid: string;
    previewImageUrl: string;
    images: [
      {
        url: string;
        alt?: string;
        blurDataURL: string;
      },
    ];
  };
  backgroundImage: {
    url?: string;
  };
  color?: {
    label: string;
    value: string;
  };
  sideNote?: string;
  price?: string;
  title?: PortableTextBlock;
  description?: PortableTextBlock;
  learnMoreButton: {
    text: string;
    link?: MenuItemLink;
  };
  buyButton: {
    text: string;
    link?: MenuItemLink;
  };
};

const fadeUp = {
  hidden: {
    opacity: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

export default function HomeFeatureCard(props: {
  card: FeatureCardType;
  index: number;
}) {
  const card = props.card;
  const index = props.index;
  const ref = useRef(null);
  const isInViewInital = useInView(ref, { amount: 0.1, once: true });
  const isInViewMixpanel = useInView(ref, { amount: 0.5, once: true });
  const { logEvent } = useStatsigClient();

  const cardColour = card.color?.label.toLowerCase() ?? 'charcoal';

  // Track product impression
  useEffect(() => {
    if (isInViewMixpanel && card && card.product) {
      mixpanelProductImpression({ product: card.product });
      logEvent('product_impression', card.product.sanityTitle, {
        event_source: 'Online',
        currency_code: 'ZAR',
        price: `${card.product.price}`,
        name: card.product.sanityTitle,
        category: card.product.categorySlug?.replace(/-/g, ' ') ?? '',
      });
    }
  }, [isInViewMixpanel, card]);

  const SVGPatternColor = useMemo(() => {
    let colorClass = '';
    switch (cardColour) {
      case 'teal':
        colorClass = '[&_path]:fill-teal-700 fill-teal-700';
        break;
      case 'pink':
        colorClass = 'fill-pink-800 [&_path]:fill-pink-800';
        break;
      case 'charcoal':
        colorClass = 'fill-charcoal-800 [&_path]:fill-charcoal-800';
        break;
      case 'orange':
        colorClass = 'fill-orange-700 [&_path]:fill-orange-700';
        break;
      default:
        break;
    }
    return colorClass;
  }, [cardColour]);

  return (
    <>
      <m.div
        ref={ref}
        initial='visible'
        animate={isInViewInital ? 'visible' : 'hidden'}
        variants={fadeUp}
        transition={{ type: 'spring', stiffness: 100 }}
        aria-hidden
        className='group relative -mb-10 flex w-full flex-col'
      >
        <IKLink
          className='block'
          link={
            card?.learnMoreButton && card?.learnMoreButton?.link
              ? card?.learnMoreButton?.link
              : { document: 'home', type: 'internal', url: '#' }
          }
        >
          <Card
            className={`relative z-10 flex-grow rounded-3xl border bg-gradient-to-b shadow-none ${
              cardColour === 'orange'
                ? 'bg-gradient-orange border-orange-600'
                : ''
            } ${
              cardColour === 'teal' ? 'bg-gradient-teal border-teal-600' : ''
            } ${
              cardColour === 'pink'
                ? 'bg-gradient-dark-pink border-pink-600'
                : ''
            } `}
          >
            {/* Background SVG pattern */}
            <div className='absolute left-0 top-0 z-0 h-full w-full overflow-hidden rounded-3xl'>
              <IKPattern
                color={cardColour as 'orange' | 'teal' | 'pink' | 'charcoal'}
                patternId={index}
                pattternProps={{
                  className: `${SVGPatternColor}`,
                }}
                className={`absolute left-0 top-0 z-0 h-[2000px] translate-x-[-50%] translate-y-[-20%] transform rounded-3xl opacity-[15%] ${SVGPatternColor}`}
              />
            </div>
            {/* On hover image overlay */}
            <div
              className={`pointer-events-none absolute left-0 top-0 z-30 hidden h-full w-full overflow-hidden rounded-3xl opacity-0 transition duration-200 group-hover:opacity-100 sm:block`}
            >
              <div
                className={`h-full w-[400%] -translate-x-[50%] rotate-12 transform bg-gradient-to-b from-20% via-30% to-50% ${
                  cardColour === 'orange'
                    ? 'from-orange-800/[0%] via-orange-800/[50%] to-orange-800'
                    : ''
                } ${
                  cardColour === 'teal'
                    ? 'from-teal-700/[0%] via-teal-700/[50%] to-teal-700'
                    : ''
                } ${
                  cardColour === 'pink'
                    ? 'from-pink-600/[0%] via-pink-700/[50%] to-pink-800'
                    : ''
                } `}
              ></div>
            </div>

            {/* Side Note text  */}
            {card?.sideNote && (
              <div className='pointer-events-none absolute right-0 top-0 flex h-full w-full justify-end'>
                <div className='relative top-0 flex h-5 w-5 origin-bottom-left rotate-90 transform overflow-visible'>
                  <div
                    className={`absolute left-0 top-4 flex w-max flex-nowrap gap-2 text-sm font-bold uppercase tracking-[2px] opacity-80 md:top-6`}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='22'
                      height='22'
                      viewBox='0 0 22 22'
                      fill='none'
                      className={` ${
                        cardColour === 'orange'
                          ? '[&>*]:fill-orange-200 [&>*]:stroke-orange-200'
                          : ''
                      } ${
                        cardColour === 'teal'
                          ? '[&>*]:fill-teal-200 [&>*]:stroke-teal-200'
                          : ''
                      } ${
                        cardColour === 'pink'
                          ? '[&>*]:fill-pink-200 [&>*]:stroke-pink-200'
                          : ''
                      } ${index > 0 ? 'rotate-[270deg]' : ''} `}
                    >
                      {index === 0 && (
                        <>
                          <path
                            d='M21.755 10.8372C21.755 8.76046 21.1392 6.73039 19.9854 5.00367C18.8317 3.27696 17.1918 1.93115 15.2732 1.13643C13.3546 0.341707 11.2434 0.133772 9.20656 0.538917C7.16976 0.944062 5.29884 1.94409 3.83038 3.41254C2.36193 4.88099 1.36191 6.75191 0.956761 8.78871C0.551616 10.8255 0.759553 12.9367 1.55427 14.8553C2.34899 16.774 3.6948 18.4138 5.42152 19.5676C7.14824 20.7213 9.1783 21.3372 11.255 21.3372C14.0389 21.3342 16.7079 20.227 18.6764 18.2585C20.6449 16.29 21.7521 13.621 21.755 10.8372ZM2.37039 10.8372C2.37039 9.07995 2.89147 7.3622 3.86772 5.90113C4.84397 4.44007 6.23156 3.3013 7.85501 2.62885C9.47846 1.95639 11.2649 1.78045 12.9883 2.12326C14.7118 2.46608 16.2948 3.31225 17.5374 4.55479C18.7799 5.79733 19.6261 7.38041 19.9689 9.10386C20.3117 10.8273 20.1358 12.6137 19.4633 14.2372C18.7909 15.8606 17.6521 17.2482 16.191 18.2244C14.73 19.2007 13.0122 19.7218 11.255 19.7218C8.89948 19.7191 6.64119 18.7822 4.97559 17.1166C3.30998 15.451 2.37306 13.1927 2.37039 10.8372Z'
                            fill='none'
                          />
                          <path
                            d='M6.40866 2.76086L9.99841 6.53009L7.84456 8.79163L13.274 14.4724M13.274 14.4724L13.274 10.0301M13.274 14.4724L8.83174 14.4724'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </>
                      )}
                      {index === 1 && (
                        <>
                          <path
                            d='M10.9997 16.8511L6.90903 12.3058C6.85176 12.2422 6.81275 12.1612 6.79694 12.073C6.78113 11.9848 6.78923 11.8933 6.82022 11.8102C6.85121 11.7271 6.90369 11.6561 6.97101 11.6062C7.03834 11.5562 7.11749 11.5296 7.19845 11.5297L10.3017 11.5297L6.90903 7.76048C6.85176 7.69691 6.81275 7.61589 6.79694 7.52768C6.78113 7.43946 6.78923 7.34801 6.82022 7.26491C6.85121 7.18182 6.90369 7.1108 6.97101 7.06086C7.03834 7.01092 7.11749 6.9843 7.19845 6.98437L15.3797 6.98438C15.4606 6.9843 15.5398 7.01092 15.6071 7.06086C15.6744 7.1108 15.7269 7.18182 15.7579 7.26492C15.7889 7.34801 15.797 7.43946 15.7812 7.52768C15.7654 7.61589 15.7264 7.69692 15.6691 7.76048L12.2764 11.5297L15.3797 11.5297C15.4606 11.5296 15.5398 11.5562 15.6071 11.6062C15.6744 11.6561 15.7269 11.7271 15.7579 11.8102C15.7889 11.8933 15.797 11.9848 15.7812 12.073C15.7654 12.1612 15.7264 12.2422 15.6691 12.3058L11.5785 16.8511C11.5405 16.8933 11.4954 16.9269 11.4457 16.9497C11.396 16.9726 11.3428 16.9844 11.2891 16.9844C11.2353 16.9844 11.1821 16.9726 11.1324 16.9497C11.0828 16.9269 11.0376 16.8933 10.9997 16.8511Z'
                            fill='#8BD0D5'
                          />
                          <path
                            d='M21.7891 11.4844C21.7891 9.40767 21.1732 7.37761 20.0195 5.65089C18.8657 3.92418 17.2259 2.57836 15.3072 1.78364C13.3886 0.988924 11.2774 0.780989 9.24062 1.18613C7.20382 1.59128 5.3329 2.59131 3.86444 4.05976C2.39599 5.52821 1.39597 7.39913 0.990821 9.43593C0.585678 11.4727 0.793613 13.5839 1.58833 15.5026C2.38305 17.4212 3.72886 19.061 5.45558 20.2148C7.18229 21.3686 9.21236 21.9844 11.2891 21.9844C14.0729 21.9814 16.7419 20.8742 18.7104 18.9057C20.6789 16.9373 21.7861 14.2682 21.7891 11.4844ZM2.40445 11.4844C2.40445 9.72717 2.92553 8.00942 3.90178 6.54835C4.87803 5.08728 6.26562 3.94852 7.88907 3.27606C9.51252 2.60361 11.2989 2.42766 13.0224 2.77048C14.7458 3.11329 16.3289 3.95947 17.5714 5.20201C18.814 6.44454 19.6601 8.02763 20.003 9.75107C20.3458 11.4745 20.1698 13.2609 19.4974 14.8844C18.8249 16.5078 17.6862 17.8954 16.2251 18.8717C14.764 19.8479 13.0463 20.369 11.2891 20.369C8.93354 20.3663 6.67525 19.4294 5.00964 17.7638C3.34404 16.0982 2.40712 13.8399 2.40445 11.4844Z'
                            fill='#8BD0D5'
                          />
                        </>
                      )}
                      {index > 1 && (
                        <>
                          <path
                            d='M21 10.5C21 8.4233 20.3842 6.39323 19.2304 4.66652C18.0767 2.9398 16.4368 1.59399 14.5182 0.799269C12.5996 0.00454912 10.4884 -0.203386 8.45155 0.201759C6.41475 0.606903 4.54383 1.60693 3.07538 3.07538C1.60693 4.54383 0.606905 6.41475 0.20176 8.45155C-0.203385 10.4884 0.00454992 12.5996 0.79927 14.5182C1.59399 16.4368 2.9398 18.0767 4.66652 19.2304C6.39323 20.3842 8.4233 21 10.5 21C13.2839 20.9971 15.9529 19.8899 17.9214 17.9214C19.8899 15.9529 20.9971 13.2839 21 10.5ZM1.61539 10.5C1.61539 8.74279 2.13646 7.02504 3.11272 5.56397C4.08897 4.10291 5.47656 2.96414 7.10001 2.29169C8.72346 1.61923 10.5099 1.44329 12.2333 1.7861C13.9568 2.12892 15.5398 2.9751 16.7824 4.21763C18.0249 5.46017 18.8711 7.04325 19.2139 8.7667C19.5567 10.4901 19.3808 12.2765 18.7083 13.9C18.0359 15.5234 16.8971 16.911 15.436 17.8873C13.975 18.8635 12.2572 19.3846 10.5 19.3846C8.14448 19.3819 5.88619 18.445 4.22058 16.7794C2.55497 15.1138 1.61806 12.8555 1.61539 10.5Z'
                            fill='#8BD0D5'
                          />
                          <path
                            d='M10.4984 15.2839C10.372 15.2842 10.2476 15.2517 10.1374 15.1898C10.0272 15.1278 9.93491 15.0383 9.86952 14.9301L5.76661 8.22316C5.69737 8.11008 5.65957 7.98057 5.65711 7.848C5.65466 7.71543 5.68763 7.58461 5.75264 7.46905C5.81664 7.35459 5.90997 7.25924 6.02304 7.19281C6.1361 7.12637 6.26482 7.09126 6.39596 7.09106L14.6009 7.09106C14.732 7.09126 14.8607 7.12637 14.9738 7.19281C15.0869 7.25924 15.1802 7.35459 15.2442 7.46905C15.3092 7.58461 15.3422 7.71543 15.3397 7.848C15.3373 7.98057 15.2995 8.11008 15.2302 8.22316L11.1273 14.9301C11.0619 15.0383 10.9696 15.1278 10.8594 15.1898C10.7492 15.2517 10.6249 15.2842 10.4984 15.2839Z'
                            fill='#8BD0D5'
                          />
                        </>
                      )}
                    </svg>
                    <span
                      className={`w-full bg-[linear-gradient(45deg,rgba(255,255,255,0.8)_10%,#fff_50%,rgba(255,255,255,0.0)_40%_100%)] bg-[length:60px_100%] bg-clip-text bg-[-50px_top] bg-no-repeat text-transparent transition-all delay-150 duration-0 group-hover:bg-[150%_top] group-hover:duration-[1s] ${
                        cardColour === 'orange' ? 'bg-orange-200' : ''
                      } ${cardColour === 'teal' ? 'bg-teal-200' : ''} ${
                        cardColour === 'pink' ? 'bg-pink-200' : ''
                      } `}
                    >
                      {card?.sideNote}
                    </span>
                  </div>
                </div>
              </div>
            )}

            {/* Card Image */}
            {card?.image && card?.image?.url && (
              <div className='z-10'>
                <Image
                  quality={100}
                  className={`pointer-events-none mx-auto -mt-16 h-[400px] translate-y-0 transform object-contain px-6 pb-2.5 transition duration-200 ease-out group-hover:-translate-y-2 sm:-ml-0 sm:-mt-16 sm:h-[530px] sm:pb-0 2xl:mb-10`}
                  priority={index === 0}
                  src={card?.image?.url}
                  width={400}
                  height={430}
                  alt={card?.image?.alt ?? ''}
                />
              </div>
            )}
            {/* Card Title */}
            {card?.title && (
              <div className='absolute bottom-0 left-0 z-40 w-full divide-y divide-white/30 px-4 pb-5 font-normal text-white md:px-8 md:pb-8'>
                <div className='space-y-2'>
                  <div
                    className={`text-lg font-bold uppercase ${
                      cardColour === 'orange' ? 'text-orange-400' : ''
                    } ${cardColour === 'teal' ? 'text-teal-600' : ''} ${
                      cardColour === 'pink' ? 'text-pink-400' : ''
                    } `}
                  >
                    <span className='text-lg 2xl:text-xl'>
                      {card.product?.price
                        ? `${formatPriceWithoutCents(card.product?.price)}`
                        : card.price}
                    </span>
                  </div>
                  <div className='inline-block max-w-[250px] text-xl leading-tight md:w-full md:max-w-[320px] 2xl:text-[27px]'>
                    <PortableText
                      components={components}
                      value={card.title}
                    ></PortableText>
                    <svg
                      className='ml-3 inline-block'
                      xmlns='http://www.w3.org/2000/svg'
                      width='17'
                      height='16'
                      viewBox='0 0 17 16'
                      fill='none'
                    >
                      <path
                        d='M9.81223 15.4466L16.2143 9.0506C16.3186 8.95578 16.4019 8.84051 16.4589 8.7121C16.5159 8.58369 16.5453 8.44494 16.5453 8.30466C16.5453 8.16438 16.5159 8.02564 16.4589 7.89723C16.4019 7.76881 16.3186 7.65354 16.2143 7.55872L9.81223 1.16268C9.62179 0.977924 9.37156 0.865835 9.10577 0.84623C8.83997 0.826625 8.57574 0.900765 8.35979 1.05554C8.23002 1.14171 8.12192 1.25621 8.04381 1.39025C7.96569 1.52429 7.91964 1.67429 7.9092 1.82873C7.89876 1.98316 7.9242 2.13791 7.98357 2.28108C8.04294 2.42424 8.13466 2.55201 8.25167 2.65456L12.8785 7.23732L1.47524 7.23732C1.19718 7.248 0.934093 7.36495 0.741174 7.56365C0.548254 7.76235 0.440491 8.02735 0.440491 8.30306C0.440491 8.57878 0.548254 8.84378 0.741174 9.04248C0.934093 9.24118 1.19718 9.35813 1.47524 9.3688L12.8801 9.3688L8.25328 13.9532C8.13627 14.0557 8.04455 14.1835 7.98518 14.3266C7.92581 14.4698 7.90037 14.6246 7.91081 14.779C7.92126 14.9334 7.96731 15.0834 8.04542 15.2175C8.12354 15.3515 8.23164 15.466 8.36141 15.5522C8.57736 15.707 8.84159 15.7811 9.10738 15.7615C9.37317 15.7419 9.62341 15.6298 9.81385 15.445L9.81223 15.4466Z'
                        fill='#FDFDFD'
                      />
                    </svg>
                  </div>
                </div>
                <div
                  className={`mt-0 hidden opacity-0 transition duration-100 group-hover:mt-5 group-hover:opacity-100 group-hover:duration-500 sm:block`}
                >
                  <div
                    className={`grid grid-rows-[0fr] transition-all duration-200 group-hover:grid-rows-[1fr]`}
                  >
                    <div className='space-y-5 overflow-hidden'>
                      <div className='mt-5 text-base lg:text-lg'>
                        {card?.description && (
                          <PortableText
                            components={components}
                            value={card?.description}
                          ></PortableText>
                        )}
                      </div>
                      <div>
                        <AcceptedPaymentMethods
                          className='max-w-[250px]'
                          color={cardColour}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Card>
        </IKLink>

        {/* Footer */}
        <div
          className={`z-0 -translate-y-10 transform overflow-hidden rounded-3xl ${cardColour === 'orange' ? 'bg-[#73220C]' : ''} ${cardColour === 'teal' ? 'bg-teal-800' : ''} ${cardColour === 'pink' ? 'bg-pink-850' : ''} `}
        >
          <div className='flex w-full items-center [&>a]:!w-1/2 [&>a]:!flex-grow'>
            <IKButton
              link={card?.learnMoreButton?.link}
              className={`flex-grow !rounded-none !bg-transparent px-2 pb-4 pt-14 text-center text-sm font-semibold uppercase tracking-[1px] !shadow-none hover:!ring-0 sm:px-5 sm:text-base lg:pb-5 lg:pt-16 ${
                cardColour === 'orange'
                  ? 'text-orange-400 hover:!bg-white/[5%]'
                  : ''
              } ${
                cardColour === 'teal'
                  ? 'text-teal-600 hover:!bg-white/[5%]'
                  : ''
              } ${
                cardColour === 'pink'
                  ? 'text-pink-400 hover:!bg-white/[5%]'
                  : ''
              } `}
            >
              {card?.learnMoreButton?.text}
            </IKButton>
            {/* Separator line */}
            <span
              className={`mt-10 h-[30px] w-[1px] flex-shrink-0 self-center opacity-30 ${cardColour === 'orange' ? 'bg-orange-300' : ''} ${cardColour === 'teal' ? 'bg-teal-300' : ''} ${cardColour === 'pink' ? 'bg-pink-300' : ''} `}
            />
            <IKButton
              ripple={card?.buyButton?.link ? true : false}
              link={card?.buyButton?.link}
              data-test-qa={card?.buyButton?.link?.addToCart ? 'pj-buy' : ''}
              className={`w-1/2 flex-grow !rounded-none !bg-transparent px-2 pb-4 pt-14 text-center text-sm font-extrabold uppercase tracking-[1px] !shadow-none hover:!ring-0 sm:px-5 md:text-base lg:pb-5 lg:pt-16 ${!card?.buyButton?.link ? 'cursor-default' : ''} ${
                cardColour === 'orange'
                  ? `text-white ${card?.buyButton?.link ? 'hover:!bg-orange-600 hover:!text-white' : ''}`
                  : ''
              } ${
                cardColour === 'teal'
                  ? `text-white ${card?.buyButton?.link ? 'hover:!bg-teal-600 hover:!text-white' : ''}`
                  : ''
              } ${
                cardColour === 'pink'
                  ? `text-white ${card?.buyButton?.link ? 'hover:!bg-pink-600 hover:!text-white' : ''}`
                  : ''
              } `}
            >
              {card?.buyButton?.text}
            </IKButton>
          </div>
        </div>
      </m.div>
    </>
  );
}
