'use client';
import React from 'react';
import Image from 'next/image';
import { IKButton } from '@/components/shared/material';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { MenuItemLink } from '@/types/types';
import { formatPriceWithoutCents } from '@/sanity/utils/shopify/priceHelpers';
import { PortableText } from 'next-sanity';
import { noParagraphs } from '@/components/sections/PortableTextComponents';

export type SlideType = {
  title: string;
  product?: {
    productId?: string;
    productGid?: string;
    slug?: string;
    categorySlug?: string;
    price?: number;
  };
  image: {
    url: string;
    alt?: string;
    placeholderUrl?: string;
  };
  button: {
    text: string;
    link: MenuItemLink;
  };
  items?: [
    {
      text?: string;
      disabled: boolean;
      image: {
        url: string;
        alt?: string;
        placeholderUrl?: string;
      };
    },
  ];
  inPersonProductResultsViewType?: string;
};

export default function ProductSlide({
  slide,
  inPersonProductResultsViewType,
}: {
  slide: SlideType;
  inPersonProductResultsViewType?: string;
}) {
  const productPrice =
    slide.product?.price && slide.product.price
      ? ` ` + formatPriceWithoutCents(slide.product?.price)
      : '';
  return (
    <>
      <div
        className={`col-span-1 grid rounded-lg ${inPersonProductResultsViewType === 'multi' ? 'w-full' : 'pt-6'} text-center`}
      >
        <div
          className={`relative h-40 ${inPersonProductResultsViewType !== 'multi' && 'lg:h-60'}`}
        >
          <Image
            src={slide.image.url}
            alt={slide.image.alt ?? slide.title}
            sizes='100vw'
            style={{
              width: 'auto',
              height: '100%',
            }}
            width={500}
            height={500}
            className='mx-auto'
          />
        </div>
        <div className={`flex items-center justify-center gap-x-2`}>
          <h2
            className={`${inPersonProductResultsViewType !== 'multi' ? 'pb-4 pt-6 font-bold lg:text-3xl' : slide?.product?.slug !== 'tap-on-phone' ? 'pt-2 font-sans font-medium' : 'pt-2 font-sans text-lg font-medium md:text-xl'} text-xl md:text-2xl`}
          >
            {typeof slide.title === 'string' ? (
              slide.title
            ) : (
              <PortableText value={slide.title} components={noParagraphs} />
            )}
          </h2>
          {/* price */}
          {inPersonProductResultsViewType === 'multi' &&
            typeof slide.product?.price === 'number' && (
              <h3 className='pt-3 text-base'>
                (
                {slide.product.price > 0
                  ? formatPriceWithoutCents(slide.product.price)
                  : 'FREE'}
                )
              </h3>
            )}
        </div>
        {slide.button && slide.button.text && (
          <div className='mx-auto w-full md:w-auto'>
            <IKButton
              link={slide.button.link}
              color={'teal'}
              // @ts-ignore
              variant={'filled'}
              size={'md'}
              className={`flex w-full min-w-full items-center justify-center gap-1 px-1 text-center md:px-8 lg:w-auto`}
            >
              <span className='text-base'>
                {slide.button.text + productPrice}
              </span>

              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='25'
                height='25'
                viewBox='0 0 25 25'
                fill='none'
              >
                <path
                  d='M17.5854 12.5703L10.0854 20.0704C10.0157 20.14 9.93302 20.1953 9.84197 20.233C9.75093 20.2707 9.65335 20.2901 9.5548 20.2901C9.45625 20.2901 9.35867 20.2707 9.26763 20.233C9.17658 20.1953 9.09386 20.14 9.02417 20.0704C8.95449 20.0007 8.89922 19.9179 8.8615 19.8269C8.82379 19.7359 8.80438 19.6383 8.80438 19.5397C8.80438 19.4412 8.82379 19.3436 8.8615 19.2526C8.89922 19.1615 8.95449 19.0788 9.02417 19.0091L15.9945 12.0397L9.02417 5.07035C8.88344 4.92962 8.80438 4.73875 8.80438 4.53972C8.80438 4.3407 8.88344 4.14983 9.02417 4.0091C9.1649 3.86837 9.35578 3.78931 9.5548 3.78931C9.75382 3.78931 9.94469 3.86837 10.0854 4.0091L17.5854 11.5091C17.6552 11.5788 17.7105 11.6615 17.7482 11.7525C17.786 11.8436 17.8054 11.9412 17.8054 12.0397C17.8054 12.1383 17.786 12.2359 17.7482 12.3269C17.7105 12.418 17.6552 12.5007 17.5854 12.5703Z'
                  fill='currentColor'
                />
              </svg>
            </IKButton>
          </div>
        )}

        {slide.items && (
          <ul
            role='list'
            className={`divide-y divide-charcoal-50/40 ${inPersonProductResultsViewType !== 'multi' ? 'py-6' : 'px-4'}`}
          >
            {slide.items.map((item, index) => (
              <li
                key={`product-slide-items-${index}`}
                className={`flex flex-col items-center justify-center gap-y-1 ${
                  item.image && item.image.url ? 'h-24' : 'h-16'
                }`}
              >
                {item.image && item.image.url && (
                  <Image
                    src={item.image.url}
                    alt={
                      item.image.alt ||
                      item.text ||
                      slide.title ||
                      'Feature icon'
                    }
                    width={24}
                    height={24}
                    className={`mx-auto ${inPersonProductResultsViewType === 'multi' ? 'h-8 w-32' : 'h-10 w-10'}`}
                  />
                )}

                <div
                  className={`flex items-center gap-2 text-center text-sm ${
                    item.disabled ? 'text-[#E0E0E0]' : 'text-charcoal'
                  }`}
                >
                  {item.image && !item.image.url && !item.disabled && (
                    <div>
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-6 w-6'
                      >
                        <rect width='16' height='16' rx='8' fill='#FFCD00' />
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M6.46896 11.4287C6.261 11.4287 6.05271 11.3476 5.89418 11.1852L3.87488 9.12103C3.55733 8.79658 3.55733 8.27035 3.87488 7.94591C4.19211 7.6213 4.70721 7.6213 5.02444 7.94591L6.46896 9.42237L10.9763 4.81499C11.2935 4.49038 11.8086 4.49038 12.1258 4.81499C12.4434 5.13943 12.4434 5.66566 12.1258 5.9901L7.04374 11.1852C6.88521 11.3476 6.67692 11.4287 6.46896 11.4287Z'
                          fill='#1D1D1B'
                        />
                      </svg>
                    </div>
                  )}

                  <span
                    className={`text-center ${inPersonProductResultsViewType && 'whitespace-nowrap font-sans'}`}
                  >
                    {item.text ?? ``}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
}
